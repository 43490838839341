"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const loadUserRegisteredChallenges = (payload) => {
    const { clientId, userId, limit, skip, status, search } = payload || {};
    return {
        type: 'OLY_LOAD_USER_REGISTERED_CHALLENGES',
        payload: {
            clientId,
            userId,
            limit,
            skip,
            status,
            search,
        },
    };
};
const loadUserRegisteredChallengesSuccess = (data) => ({
    type: 'OLY_LOAD_USER_REGISTERED_CHALLENGES_SUCCESS',
    payload: { ...data },
});
const loadUserRegisteredChallengesError = (error) => ({
    type: 'OLY_LOAD_USER_REGISTERED_CHALLENGES_ERROR',
    payload: { error },
});
const resetUserRegisteredChallenges = () => ({
    type: 'OLY_RESET_USER_REGISTERED_CHALLENGES',
});
// Load user contribution for groupChallenge
const loadUserGroupChallengeStats = (payload) => ({
    type: 'OLY_LOAD_USER_GROUP_CHALLENGE_STATS_REQUEST',
    payload,
});
const loadUserGroupChallengeStatsSuccess = (data) => ({
    type: 'OLY_LOAD_USER_GROUP_CHALLENGE_STATS_SUCCESS',
    payload: { ...data },
});
const loadUserGroupChallengeStatsError = (error) => ({
    type: 'OLY_LOAD_USER_GROUP_CHALLENGE_STATS_ERROR',
    payload: { error },
});
const resetUserGroupChallengeStats = () => ({
    type: 'OLY_RESET_USER_GROUP_CHALLENGE_STATS',
});
// Register to challenge
const challengeRegister = (payload) => ({
    type: 'OLY_REGISTER_CHALLENGE_REQUEST',
    payload,
});
const challengeRegisterSuccess = () => ({
    type: 'OLY_REGISTER_CHALLENGE_SUCCESS',
});
const challengeRegisterError = (error) => ({
    type: 'OLY_REGISTER_CHALLENGE_ERROR',
    payload: error,
});
// Load user challenge
const loadUserChallenge = (payload) => ({
    type: 'OLY_LOAD_USER_CHALLENGE_REQUEST',
    payload,
});
const loadUserChallengeSuccess = (data) => ({
    type: 'OLY_LOAD_USER_CHALLENGE_SUCCESS',
    payload: data,
});
const loadUserChallengeError = (error) => ({
    type: 'OLY_LOAD_USER_CHALLENGE_ERROR',
    payload: error,
});
// Update user challenge
const updateUserChallenge = (payload) => ({
    type: 'OLY_UPDATE_USER_CHALLENGE_REQUEST',
    payload,
});
const updateUserChallengeSuccess = () => ({
    type: 'OLY_UPDATE_USER_CHALLENGE_SUCCESS',
});
const updateUserChallengeError = () => ({
    type: 'OLY_UPDATE_USER_CHALLENGE_ERROR',
});
// Get user challenge activities
const loadUserChallengeActivities = (payload) => ({
    type: 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES',
    payload,
});
const loadUserChallengeActivitiesSuccess = (data) => ({
    type: 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_SUCCESS',
    payload: data,
});
const loadUserChallengeActivitiesError = () => ({
    type: 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_ERROR',
});
// Get user challenge actions
const loadUserChallengeActions = (payload) => ({
    type: 'OLY_LOAD_USER_CHALLENGE_ACTIONS',
    payload,
});
const resetUserChallengeActions = () => ({
    type: 'OLY_RESET_USER_CHALLENGE_ACTIONS',
});
const loadUserChallengeActionsSuccess = (data) => ({
    type: 'OLY_LOAD_USER_CHALLENGE_ACTIONS_SUCCESS',
    payload: data,
});
const loadUserChallengeActionsError = () => ({
    type: 'OLY_LOAD_USER_CHALLENGE_ACTIONS_ERROR',
});
// Get user challenge activities around
const loadUserChallengeActivitiesAround = (payload) => ({
    type: 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_AROUND',
    payload,
});
const loadUserChallengeActivitiesAroundSuccess = (data) => ({
    type: 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_AROUND_SUCCESS',
    payload: data,
});
const loadUserChallengeActivitiesAroundError = () => ({
    type: 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_AROUND_ERROR',
});
const resetUserChallengeActivities = () => ({
    type: 'OLY_RESET_USER_CHALLENGE_ACTIVITIES',
});
// Get user challenge attempts
const loadUserChallengeAttempts = (payload) => ({
    type: 'OLY_LOAD_USER_CHALLENGE_ATTEMPTS',
    payload,
});
const loadUserChallengeAttemptsSuccess = (data) => ({
    type: 'OLY_LOAD_USER_CHALLENGE_ATTEMPTS_SUCCESS',
    payload: data,
});
const loadUserChallengeAttemptsError = () => ({
    type: 'OLY_LOAD_USER_CHALLENGE_ATTEMPTS_ERROR',
});
const resetUserChallengeAttempts = () => ({
    type: 'OLY_RESET_USER_CHALLENGE_ATTEMPTS',
});
// Reset user challenges state
const resetUserChallenges = () => ({
    type: 'OLY_RESET_USER_CHALLENGES',
});
const loadUserGroupChallengeRequest = (payload) => ({
    type: 'OLY_LOAD_USER_GROUP_CHALLENGE_REQUEST',
    payload,
});
const loadUserGroupChallengeSuccess = (payload) => ({
    type: 'OLY_LOAD_USER_GROUP_CHALLENGE_SUCCESS',
    payload,
});
const resetUserGroupChallenge = () => ({
    type: 'OLY_RESET_USER_GROUP_CHALLENGE',
});
const setUserGroupChallengeAttempt = (payload) => ({
    type: 'OLY_SET_USER_GROUP_CHALLENGE_ATTEMPT',
    payload,
});
const setUserGroupChallengeSuccess = (payload) => ({
    type: 'OLY_SET_USER_GROUP_CHALLENGE',
    payload,
});
const userGroupChallengeRequestError = () => ({
    type: 'OLY_USER_GROUP_CHALLENGE_REQUEST_ERROR',
});
exports.default = {
    challengeRegister,
    challengeRegisterError,
    challengeRegisterSuccess,
    loadUserChallenge,
    loadUserChallengeActivities,
    loadUserChallengeActions,
    loadUserChallengeActivitiesAround,
    loadUserChallengeActivitiesAroundError,
    loadUserChallengeActivitiesAroundSuccess,
    loadUserChallengeActivitiesError,
    loadUserChallengeActivitiesSuccess,
    loadUserChallengeActionsSuccess,
    loadUserChallengeActionsError,
    loadUserChallengeAttempts,
    loadUserChallengeAttemptsError,
    loadUserChallengeAttemptsSuccess,
    loadUserChallengeError,
    loadUserChallengeSuccess,
    loadUserGroupChallengeStats,
    loadUserGroupChallengeStatsError,
    loadUserGroupChallengeStatsSuccess,
    loadUserRegisteredChallenges,
    loadUserRegisteredChallengesError,
    loadUserRegisteredChallengesSuccess,
    loadUserGroupChallengeSuccess,
    loadUserGroupChallengeRequest,
    resetUserChallengeActivities,
    resetUserChallengeActions,
    resetUserChallengeAttempts,
    resetUserChallenges,
    resetUserGroupChallengeStats,
    resetUserRegisteredChallenges,
    resetUserGroupChallenge,
    setUserGroupChallengeAttempt,
    setUserGroupChallengeSuccess,
    updateUserChallenge,
    updateUserChallengeError,
    updateUserChallengeSuccess,
    userGroupChallengeRequestError,
};
