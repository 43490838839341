import { createSelector } from 'reselect';
import get from 'lodash/get';

const modalSelector = state => get(state, 'modal');

export const modalDeleteChallengeSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'challenge' ? modal : {},
);

export const modalRegisterChallengeSelector = createSelector(modalSelector, modal =>
  modal.type === 'register' && modal.entity === 'challenge' ? modal : {},
);

export const modalAutoTranslateChallengeSelector = createSelector(modalSelector, modal =>
  modal.type === 'autoTranslate' && modal.entity === 'challenge' ? modal : {},
);

export const modalChallengeTypeSelector = createSelector(modalSelector, modal =>
  modal.type === 'info' && modal.entity === 'challengeType' ? modal : {},
);

export const modalDeleteUserSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'user' ? modal : {},
);

export const modalDeleteRewardSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'reward' ? modal : {},
);

export const modalDeleteUserFromLeaderboardSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'userLeaderboard' ? modal : {},
);

export const modalDeleteUserFromGroupSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'userGroup' ? modal : {},
);

export const modalDeleteUserAccountSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'userAccount' ? modal : {},
);

export const modalDeleteUserClientSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'userClient' ? modal : {},
);

export const modalDeleteCollectionSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'collection' ? modal : {},
);

export const modalDeleteArticleSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'article' ? modal : {},
);

export const modalDeleteProgramSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'program' ? modal : {},
);

export const modalDuplicationProgramSelector = createSelector(modalSelector, modal =>
  modal.type === 'duplicate' && modal.entity === 'program' ? modal : {},
);

export const modalEditUserSelector = createSelector(modalSelector, modal =>
  modal.type === 'edit' && modal.entity === 'user' ? modal : {},
);

export const modalUserActivityReloadSelector = createSelector(modalSelector, modal =>
  modal.type === 'reload' && modal.entity === 'activities' ? modal : {},
);

export const modalActivityErrorsSelector = createSelector(modalSelector, modal =>
  modal.type === 'error' && modal.entity === 'activity' ? modal : {},
);

export const modalUserActivityDeleteSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'activity' ? modal : {},
);

export const modalUserAppDeleteSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'app' ? modal : {},
);

export const modalDebugSelector = createSelector(modalSelector, modal =>
  modal.type === 'debug' && modal.entity === 'common' ? modal : {},
);

export const modalChallengeActivitiesDisplaySelector = createSelector(modalSelector, modal =>
  modal.type === 'display' && modal.entity === 'challengeActivities' ? modal : {},
);

export const modalChallengeAttemptsDisplaySelector = createSelector(modalSelector, modal =>
  modal.type === 'display' && modal.entity === 'userChallengeAttempts' ? modal : {},
);

export const modalEditUserChallengeSelector = createSelector(modalSelector, modal =>
  modal.type === 'edit' && modal.entity === 'userChallenge' ? modal : {},
);

export const modalRoleDeleteSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'role' ? modal : {},
);

export const modalRoleSelector = createSelector(modalSelector, modal =>
  modal.type === 'createOrEdit' && modal.entity === 'role' ? modal : {},
);

export const modalGroupsUploadSelector = createSelector(modalSelector, modal =>
  modal.type === 'info' && modal.entity === 'groupsUpload' ? modal : {},
);

export const modalGroupsImportSelector = createSelector(modalSelector, modal =>
  modal.type === 'info' && modal.entity === 'groupsImport' ? modal : {},
);

export const modalExportsSelector = createSelector(modalSelector, modal =>
  modal.type === 'run' && modal.entity === 'exports' ? modal : {},
);

export const modalGroupEditSelector = createSelector(modalSelector, modal =>
  modal.type === 'edit' && modal.entity === 'group' ? modal : {},
);

export const modalGroupDeleteSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'group' ? modal : {},
);

export const modalCreateSubGroupsSelector = createSelector(modalSelector, modal =>
  modal.type === 'create' && modal.entity === 'group' ? modal : {},
);

export const modalSegmentCreateSelector = createSelector(modalSelector, modal =>
  modal.type === 'create' && modal.entity === 'segment' ? modal : {},
);

export const modalSegmentDeleteSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'segment' ? modal : {},
);

export const modalSegmentEditSelector = createSelector(modalSelector, modal =>
  modal.type === 'edit' && modal.entity === 'segment' ? modal : {},
);

export const modalSegmentInfoSelector = createSelector(modalSelector, modal =>
  modal.type === 'info' && modal.entity === 'segment' ? modal : {},
);

export const modalLanguageSelector = createSelector(modalSelector, modal =>
  modal.type === 'config' && modal.entity === 'language' ? modal : {},
);

export const modalDeleteUsersFromGroupSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'usersGroup' ? modal : {},
);

export const modalSwitchUsersGroupSelector = createSelector(modalSelector, modal =>
  modal.type === 'switch' && modal.entity === 'usersGroup' ? modal : {},
);

export const modalDuplicationSelector = createSelector(modalSelector, modal =>
  modal.type === 'duplication' && modal.entity === 'validation' ? modal : {},
);

export const modalActivitySelector = createSelector(modalSelector, modal =>
  modal.type === 'createOrEdit' && modal.entity === 'activity' ? modal : {},
);

export const modalDuplicationGenericSelector = createSelector(modalSelector, modal =>
  modal.type === 'duplicationGeneric' && modal.entity === 'common' ? modal : {},
);

export const modalMagicLinkSelector = createSelector(modalSelector, modal =>
  modal.type === 'info' && modal.entity === 'teams' ? modal : {},
);
export const modalCookiesSelector = createSelector(modalSelector, modal =>
  modal.type === 'cookies' && modal.entity === 'common' ? modal : {},
);

export const modalInvitationsLanguageSelector = createSelector(modalSelector, modal =>
  modal.type === 'config' && modal.entity === 'languageInvitations' ? modal : {},
);

export const modalDeleteOrApproveReportedMessagesSelector = createSelector(modalSelector, modal =>
  modal.type === 'deleteOrApprove' && modal.entity === 'reportedMessages' ? modal : {},
);
export const modalAddLangSelector = createSelector(modalSelector, modal =>
  modal.type === 'add' && modal.entity === 'lang' ? modal : {},
);

export const modalFormConfirmationSelector = createSelector(modalSelector, modal =>
  modal.type === 'confirmation' && modal.entity === 'form' ? modal : {},
);

export const modalAddReferralSelector = createSelector(modalSelector, modal =>
  modal.type === 'add' && modal.entity === 'referral' ? modal : {},
);

export const modalChallengeDetailsSelector = createSelector(modalSelector, modal =>
  modal.type === 'details' && modal.entity === 'challenge' ? modal : {},
);

export const modalDeleteOrApproveLangSelector = createSelector(modalSelector, modal =>
  modal.type === 'deleteOrApprove' && modal.entity === 'deleteLang' ? modal : {},
);

export const modalQuizDetailsSelector = createSelector(modalSelector, modal =>
  modal.type === 'details' && modal.entity === 'quiz' ? modal : {},
);

export const modalDeleteQuizConfirmationSelector = createSelector(modalSelector, modal =>
  modal.type === 'delete' && modal.entity === 'quiz' ? modal : {},
);

export const modalUserQuizSelector = createSelector(modalSelector, modal =>
  modal.type === 'details' && modal.entity === 'userQuiz' ? modal : {},
);
