import React from 'react';
import { connect } from 'react-redux';

import {
  modalDeleteChallengeSelector,
  modalDeleteRewardSelector,
  modalUserAppDeleteSelector,
  modalDeleteUserFromLeaderboardSelector,
  modalDeleteUserAccountSelector,
  modalDeleteUserClientSelector,
  modalDeleteUserFromGroupSelector,
  modalDeleteCollectionSelector,
  modalDeleteArticleSelector,
  modalDeleteProgramSelector,
  modalDuplicationProgramSelector,
  modalUserActivityReloadSelector,
  modalActivityErrorsSelector,
  modalUserActivityDeleteSelector,
  modalEditUserChallengeSelector,
  modalChallengeActivitiesDisplaySelector,
  modalChallengeAttemptsDisplaySelector,
  modalRegisterChallengeSelector,
  modalAutoTranslateChallengeSelector,
  modalChallengeTypeSelector,
  modalRoleSelector,
  modalRoleDeleteSelector,
  modalDebugSelector,
  modalEditUserSelector,
  modalGroupsUploadSelector,
  modalGroupsImportSelector,
  modalExportsSelector,
  modalGroupEditSelector,
  modalGroupDeleteSelector,
  modalCreateSubGroupsSelector,
  modalSegmentCreateSelector,
  modalSegmentDeleteSelector,
  modalSegmentInfoSelector,
  modalSegmentEditSelector,
  modalLanguageSelector,
  modalDeleteUsersFromGroupSelector,
  modalSwitchUsersGroupSelector,
  modalDuplicationSelector,
  modalActivitySelector,
  modalDuplicationGenericSelector,
  modalMagicLinkSelector,
  modalCookiesSelector,
  modalInvitationsLanguageSelector,
  modalDeleteOrApproveReportedMessagesSelector,
  modalAddLangSelector,
  modalFormConfirmationSelector,
  modalAddReferralSelector,
  modalDeleteOrApproveLangSelector,
  modalChallengeDetailsSelector,
  modalDeleteQuizConfirmationSelector,
  modalQuizDetailsSelector,
  modalUserQuizSelector,
} from 'redux/modal/selectors';

const ModalDeleteChallenge = React.lazy(() =>
  import(/* webpackChunkName: "ModalDeleteChallenge" */ './ModalDeleteChallenge'),
);
const ModalDeleteUserFromLeaderboard = React.lazy(() =>
  import(
    /* webpackChunkName: "ModalDeleteUserFromLeaderboard" */ './ModalDeleteUserFromLeaderboard'
  ),
);
const ModalDeleteUserAccount = React.lazy(() =>
  import(
    /* webpackChunkName: "ModalDeleteUser_ModalDeleteUserAccount" */ './ModalDeleteUser/ModalDeleteUserAccount'
  ),
);
const ModalDeleteUserClient = React.lazy(() =>
  import(
    /* webpackChunkName: "ModalDeleteUser_ModalDeleteUserClient" */ './ModalDeleteUser/ModalDeleteUserClient'
  ),
);
const ModalDeleteUserFromGroup = React.lazy(() =>
  import(/* webpackChunkName: "ModalDeleteUserFromGroup" */ './ModalDeleteUserFromGroup'),
);
const ModalDeleteReward = React.lazy(() =>
  import(/* webpackChunkName: "ModalDeleteReward" */ './ModalDeleteReward'),
);
const ModalDeleteUserApp = React.lazy(() =>
  import(/* webpackChunkName: "ModalDeleteUserApp" */ './ModalDeleteUserApp'),
);
const ModalDeleteCollection = React.lazy(() =>
  import(/* webpackChunkName: "ModalDeleteCollection" */ './ModalDeleteCollection'),
);
const ModalDeleteArticle = React.lazy(() =>
  import(/* webpackChunkName: "ModalDeleteArticle" */ './ModalDeleteArticle'),
);
const ModalDeleteProgram = React.lazy(() =>
  import(/* webpackChunkName: "ModalDeleteProgram" */ './ModalDeleteProgram'),
);
const ModalDuplicateProgram = React.lazy(() =>
  import(/* webpackChunkName: "ModalDuplicateProgram" */ './ModalDuplicateProgram'),
);
const ModalActivitiesReload = React.lazy(() =>
  import(/* webpackChunkName: "ModalActivitiesReload" */ './ModalActivitiesReload'),
);
const ModalActivityErrors = React.lazy(() =>
  import(/* webpackChunkName: "ModalActivityErrors" */ './ModalActivityErrors'),
);
const ModalUserActivityDelete = React.lazy(() =>
  import(/* webpackChunkName: "ModalUserActivity_Delete" */ './ModalUserActivity/Delete'),
);
const ModalEditUserChallenge = React.lazy(() =>
  import(/* webpackChunkName: "ModalEditUserChallenge" */ './ModalEditUserChallenge'),
);
const ModalChallengeActivities = React.lazy(() =>
  import(/* webpackChunkName: "ModalChallengeActivities" */ './ModalChallengeActivities'),
);
const ModalChallengeAttempts = React.lazy(() =>
  import(/* webpackChunkName: "ModalChallengeAttempts" */ './ModalChallengeAttempts'),
);
const ModalChallengeRegister = React.lazy(() =>
  import(/* webpackChunkName: "ModalChallengeRegister" */ './ModalChallengeRegister'),
);
const ModalChallengeAutoTranslate = React.lazy(() =>
  import(/* webpackChunkName: "ModalChallengeAutoTranslate" */ './ModalChallengeAutoTranslate'),
);
const ModalChallengeType = React.lazy(() =>
  import(/* webpackChunkName: "ModalChallengeType" */ './ModalChallengeType'),
);
const ModalRole = React.lazy(() => import(/* webpackChunkName: "ModalRole" */ './ModalRole'));
const ModalRoleDelete = React.lazy(() =>
  import(/* webpackChunkName: "ModalRoleDelete" */ './ModalRoleDelete'),
);
const ModalDebug = React.lazy(() => import(/* webpackChunkName: "ModalDebug" */ './ModalDebug'));
const ModalEditUser = React.lazy(() =>
  import(/* webpackChunkName: "ModalEditUser" */ './ModalEditUser'),
);
const ModalGroupsUpload = React.lazy(() =>
  import(/* webpackChunkName: "ModalGroups_Upload" */ './ModalGroups/Upload'),
);
const ModalGroupsImport = React.lazy(() =>
  import(/* webpackChunkName: "ModalGroups_Import" */ './ModalGroups/Import'),
);
const ModalGroupsEdit = React.lazy(() =>
  import(/* webpackChunkName: "ModalGroups_Edit" */ './ModalGroups/Edit'),
);
const ModalGroupsDelete = React.lazy(() =>
  import(/* webpackChunkName: "ModalGroups_Delete" */ './ModalGroups/Delete'),
);
const ModalCreateSubGroups = React.lazy(() =>
  import(/* webpackChunkName: "ModalGroups_Create" */ './ModalGroups/Create'),
);
const ModalExports = React.lazy(() =>
  import(/* webpackChunkName: "ModalExports" */ './ModalExports'),
);
const ModalSegment = React.lazy(() =>
  import(/* webpackChunkName: "ModalSegment" */ './ModalSegment'),
);
const ModalSegmentDelete = React.lazy(() =>
  import(/* webpackChunkName: "ModalSegmentDelete" */ './ModalSegmentDelete'),
);

const ModalSegmentImpactedChallenges = React.lazy(() =>
  import(
    /* webpackChunkName: "ModalSegmentImpactedChallenges" */ './ModalSegmentImpactedChallenges'
  ),
);

const ModalLanguage = React.lazy(() =>
  import(/* webpackChunkName: "ModalLanguage" */ './ModalLanguage'),
);

const ModalDeleteUsersFromGroup = React.lazy(() =>
  import(/* webpackChunkName: "ModalLanguage" */ './ModalDeleteUsersFromGroup'),
);

const ModalUsersSwitchGroup = React.lazy(() =>
  import(/* webpackChunkName: "ModalLanguage" */ './ModalUsersSwitchGroup'),
);

const ModalDuplication = React.lazy(() =>
  import(/* webpackChunkName: "ModalLanguage" */ './ModalDuplication'),
);

const ModalActivity = React.lazy(() =>
  import(/* webpackChunkName: "ModalLanguage" */ './ModalActivity'),
);

const ModalDuplicationGeneric = React.lazy(() =>
  import(/* webpackChunkName: "ModalDuplicationGeneric" */ './ModalDuplicationGeneric'),
);

const ModalMagicLink = React.lazy(() =>
  import(/* webpackChunkName: "ModalMagicLink" */ './ModalMagicLink'),
);
const ModalCookies = React.lazy(() =>
  import(/* webpackChunkName: "ModalCookies" */ './ModalCookies'),
);

const ModalInvitationsLanguage = React.lazy(() =>
  import(/* webpackChunkName: "ModalInvitationsLanguage" */ './ModalInvitationsLanguage'),
);

const ModalDeleteOrApproveReportedMessages = React.lazy(() =>
  import(
    /* webpackChunkName: "ModalDeleteOrApproveReportedMessages" */ './ModalDeleteOrApproveReportedMessages'
  ),
);

const ModalAddLang = React.lazy(() =>
  import(/* webpackChunkName: "ModalAddLang" */ './ModalAddLang'),
);

const ModalFormConfirmation = React.lazy(() =>
  import(
    /* webpackChunkName: "ModalFormConfirmation" */ '../../form/Views/Modals/ModalFormConfirmation'
  ),
);

const ModalAddReferral = React.lazy(() =>
  import(/* webpackChunkName: "ModalAddReferral" */ './ModalAddReferral'),
);

const ModalChallengeDetails = React.lazy(() =>
  import(/* webpackChunkName: "ModalChallengeDetails" */ './ModalChallengeDetails'),
);

const ModalDeleteOrApproveLang = React.lazy(() =>
  import(/* webpackChunkName: "ModalDeleteOrApproveLang" */ './ModalDeleteOrApproveLang'),
);

const ModalQuizDetails = React.lazy(() =>
  import(/*webpackChunkName: "ModalQuizDetails"*/ './ModalQuizDetails'),
);

const ModalDeleteQuizConfirmation = React.lazy(() =>
  import(/*webpackChunkName: "ModalDeleteQuizConfirmation"*/ './ModalDeleteQuizConfirmation'),
);

const ModalUserQuiz = React.lazy(() =>
  import(/*webpackChunkName: "ModalUserQuiz"*/ './ModalUserQuiz'),
);

const Modals = ({
  isDeleteChallengeModalOpen,
  isDeleteRewardModalOpen,
  isUserAppDeleteModalOpen,
  isDeleteUserFromLeaderboardModalOpen,
  isDeleteUserAccountModalOpen,
  isDeleteUserClientModalOpen,
  isDeleteUserFromGroupModalOpen,
  isDeleteCollectionModalOpen,
  isDeleteArticleModalOpen,
  isProgramDeleteModalOpen,
  isProgramDuplicateModalOpen,
  isUserActivityReloadModalOpen,
  isActivityErrorsModalOpen,
  isUserActivityDeleteModalOpen,
  isEditUserChallengeModalOpen,
  isChallengeActivitiesDisplayModalOpen,
  isChallengeAttemptsDisplayModalOpen,
  isRegisterChallengeModalOpen,
  isAutoTranslateChallengeModalOpen,
  isChallengeTypeModalOpen,
  isRoleModalOpen,
  isRoleDeleteModalOpen,
  isDebugSelectorModalOpen,
  isEditUserModalOpen,
  isGroupsUploadModalOpen,
  isGroupsImportModalOpen,
  isExportsModalOpen,
  isGroupEditModalOpen,
  isGroupDeleteModalOpen,
  isCreateSubGroupsOpen,
  isLanguageModalOpen,
  isSegmentCreateModalOpen,
  isSegmentEditModalOpen,
  isSegmentDeleteModalOpen,
  isSegmentImpactedChallengesModalOpen,
  isModalDeleteUsersFromGroup,
  isModalUsersSwitchGroup,
  isModalDuplication,
  isModalActivity,
  isModalDuplicationGeneric,
  isModalMagicLinkOpen,
  isModalCookies,
  isModalInvitationsLanguageOpen,
  isModalDeleteOrApproveReportedMessagesOpen,
  isModalAddLangOpen,
  isModalFormConfirmationOpen,
  isModalAddReferralOpen,
  isModalDeleteOrApproveLang,
  isModalChallengeDetailsOpen,
  isModalQuizDetailsOpen,
  isModalDeleteQuizConfirmationOpen,
  isModalUserQuizOpen,
}) => {
  return (
    <React.Suspense fallback={null}>
      {isDeleteChallengeModalOpen && <ModalDeleteChallenge />}
      {isDeleteRewardModalOpen && <ModalDeleteReward />}
      {isUserAppDeleteModalOpen && <ModalDeleteUserApp />}
      {isDeleteUserFromLeaderboardModalOpen && <ModalDeleteUserFromLeaderboard />}
      {isDeleteUserAccountModalOpen && <ModalDeleteUserAccount />}
      {isDeleteUserClientModalOpen && <ModalDeleteUserClient />}
      {isDeleteUserFromGroupModalOpen && <ModalDeleteUserFromGroup />}
      {isDeleteCollectionModalOpen && <ModalDeleteCollection />}
      {isDeleteArticleModalOpen && <ModalDeleteArticle />}
      {isProgramDeleteModalOpen && <ModalDeleteProgram />}
      {isProgramDuplicateModalOpen && <ModalDuplicateProgram />}
      {isUserActivityReloadModalOpen && <ModalActivitiesReload />}
      {isActivityErrorsModalOpen && <ModalActivityErrors />}
      {isUserActivityDeleteModalOpen && <ModalUserActivityDelete />}
      {isEditUserChallengeModalOpen && <ModalEditUserChallenge />}
      {isChallengeActivitiesDisplayModalOpen && <ModalChallengeActivities />}
      {isChallengeAttemptsDisplayModalOpen && <ModalChallengeAttempts />}
      {isRegisterChallengeModalOpen && <ModalChallengeRegister />}
      {isAutoTranslateChallengeModalOpen && <ModalChallengeAutoTranslate />}
      {isChallengeTypeModalOpen && <ModalChallengeType />}
      {isRoleModalOpen && <ModalRole />}
      {isRoleDeleteModalOpen && <ModalRoleDelete />}
      {isDebugSelectorModalOpen && <ModalDebug />}
      {isEditUserModalOpen && <ModalEditUser />}
      {isGroupsUploadModalOpen && <ModalGroupsUpload />}
      {isGroupsImportModalOpen && <ModalGroupsImport />}
      {isExportsModalOpen && <ModalExports />}
      {isGroupEditModalOpen && <ModalGroupsEdit />}
      {isGroupDeleteModalOpen && <ModalGroupsDelete />}
      {isCreateSubGroupsOpen && <ModalCreateSubGroups />}
      {(isSegmentCreateModalOpen || isSegmentEditModalOpen) && <ModalSegment />}
      {isSegmentDeleteModalOpen && <ModalSegmentDelete />}
      {isSegmentImpactedChallengesModalOpen && <ModalSegmentImpactedChallenges />}
      {isLanguageModalOpen && <ModalLanguage />}
      {isModalDeleteUsersFromGroup && <ModalDeleteUsersFromGroup />}
      {isModalUsersSwitchGroup && <ModalUsersSwitchGroup />}
      {isModalDuplication && <ModalDuplication />}
      {isModalActivity && <ModalActivity />}
      {isModalDuplicationGeneric && <ModalDuplicationGeneric />}
      {isModalMagicLinkOpen && <ModalMagicLink />}
      {isModalCookies && <ModalCookies />}
      {isModalInvitationsLanguageOpen && <ModalInvitationsLanguage />}
      {isModalDeleteOrApproveReportedMessagesOpen && <ModalDeleteOrApproveReportedMessages />}
      {isModalAddLangOpen && <ModalAddLang />}
      {isModalFormConfirmationOpen && <ModalFormConfirmation />}
      {isModalAddReferralOpen && <ModalAddReferral />}
      {isModalDeleteOrApproveLang && <ModalDeleteOrApproveLang />}
      {isModalChallengeDetailsOpen && <ModalChallengeDetails />}
      {isModalQuizDetailsOpen && <ModalQuizDetails />}
      {isModalDeleteQuizConfirmationOpen && <ModalDeleteQuizConfirmation />}
      {isModalUserQuizOpen && <ModalUserQuiz />}
    </React.Suspense>
  );
};

const mapStateToProps = state => ({
  isDeleteChallengeModalOpen: modalDeleteChallengeSelector(state).isOpen,
  isDeleteRewardModalOpen: modalDeleteRewardSelector(state).isOpen,
  isUserAppDeleteModalOpen: modalUserAppDeleteSelector(state).isOpen,
  isDeleteUserFromLeaderboardModalOpen: modalDeleteUserFromLeaderboardSelector(state).isOpen,
  isDeleteUserAccountModalOpen: modalDeleteUserAccountSelector(state).isOpen,
  isDeleteUserClientModalOpen: modalDeleteUserClientSelector(state).isOpen,
  isDeleteUserFromGroupModalOpen: modalDeleteUserFromGroupSelector(state).isOpen,
  isDeleteCollectionModalOpen: modalDeleteCollectionSelector(state).isOpen,
  isDeleteArticleModalOpen: modalDeleteArticleSelector(state).isOpen,
  isProgramDeleteModalOpen: modalDeleteProgramSelector(state).isOpen,
  isProgramDuplicateModalOpen: modalDuplicationProgramSelector(state).isOpen,
  isUserActivityReloadModalOpen: modalUserActivityReloadSelector(state).isOpen,
  isActivityErrorsModalOpen: modalActivityErrorsSelector(state).isOpen,
  isUserActivityDeleteModalOpen: modalUserActivityDeleteSelector(state).isOpen,
  isEditUserChallengeModalOpen: modalEditUserChallengeSelector(state).isOpen,
  isChallengeActivitiesDisplayModalOpen: modalChallengeActivitiesDisplaySelector(state).isOpen,
  isChallengeAttemptsDisplayModalOpen: modalChallengeAttemptsDisplaySelector(state).isOpen,
  isRegisterChallengeModalOpen: modalRegisterChallengeSelector(state).isOpen,
  isAutoTranslateChallengeModalOpen: modalAutoTranslateChallengeSelector(state).isOpen,
  isChallengeTypeModalOpen: modalChallengeTypeSelector(state).isOpen,
  isRoleModalOpen: modalRoleSelector(state).isOpen,
  isRoleDeleteModalOpen: modalRoleDeleteSelector(state).isOpen,
  isDebugSelectorModalOpen: modalDebugSelector(state).isOpen,
  isEditUserModalOpen: modalEditUserSelector(state).isOpen,
  isGroupsUploadModalOpen: modalGroupsUploadSelector(state).isOpen,
  isGroupsImportModalOpen: modalGroupsImportSelector(state).isOpen,
  isExportsModalOpen: modalExportsSelector(state).isOpen,
  isGroupEditModalOpen: modalGroupEditSelector(state).isOpen,
  isGroupDeleteModalOpen: modalGroupDeleteSelector(state).isOpen,
  isCreateSubGroupsOpen: modalCreateSubGroupsSelector(state).isOpen,
  isSegmentCreateModalOpen: modalSegmentCreateSelector(state).isOpen,
  isSegmentEditModalOpen: modalSegmentEditSelector(state).isOpen,
  isSegmentDeleteModalOpen: modalSegmentDeleteSelector(state).isOpen,
  isSegmentImpactedChallengesModalOpen: modalSegmentInfoSelector(state).isOpen,
  isLanguageModalOpen: modalLanguageSelector(state).isOpen,
  isModalDeleteUsersFromGroup: modalDeleteUsersFromGroupSelector(state).isOpen,
  isModalUsersSwitchGroup: modalSwitchUsersGroupSelector(state).isOpen,
  isModalDuplication: modalDuplicationSelector(state).isOpen,
  isModalActivity: modalActivitySelector(state).isOpen,
  isModalDuplicationGeneric: modalDuplicationGenericSelector(state).isOpen,
  isModalMagicLinkOpen: modalMagicLinkSelector(state).isOpen,
  isModalCookies: modalCookiesSelector(state).isOpen,
  isModalInvitationsLanguageOpen: modalInvitationsLanguageSelector(state).isOpen,
  isModalDeleteOrApproveReportedMessagesOpen: modalDeleteOrApproveReportedMessagesSelector(state)
    .isOpen,
  isModalAddLangOpen: modalAddLangSelector(state).isOpen,
  isModalFormConfirmationOpen: modalFormConfirmationSelector(state).isOpen,
  isModalAddReferralOpen: modalAddReferralSelector(state).isOpen,
  isModalDeleteOrApproveLang: modalDeleteOrApproveLangSelector(state).isOpen,
  isModalChallengeDetailsOpen: modalChallengeDetailsSelector(state).isOpen,
  isModalDeleteQuizConfirmationOpen: modalDeleteQuizConfirmationSelector(state).isOpen,
  isModalQuizDetailsOpen: modalQuizDetailsSelector(state).isOpen,
  isModalUserQuizOpen: modalUserQuizSelector(state).isOpen,
});

export default connect(mapStateToProps)(Modals);
